<template>
  <div v-if="widgets">
    <template v-for="item in widgets">
      <mounting-portal
        v-if="item.slides.length >= item.minCount"
        :key="item.zoneId"
        :mount-to="getTargetSelector(item.zoneId)"
      >
        <mz-recommendations
          primary-key="id"
          :query-id="queryId"
          :title="item.title"
          :css-class="item.layout.cssClass"
          :layout="item.layout.layout"
          :template="item.layout.template"
          :slides="item.slides"
          :settings="item.layout.settings"
          @recommendation-click="onClick"
        ></mz-recommendations>
      </mounting-portal>
    </template>
  </div>
</template>

<script>
/* global mz */
import { mapState } from 'vuex'
import { MzRecommendations } from '@mezereon/ui-components-vue'
import { setCustomCSS } from '@/helpers'

export default {
  components: { MzRecommendations },
  data() {
    return {
      widgets: null,
      queryId: null,
      segments: null
    }
  },
  computed: {
    ...mapState('search', ['context'])
  },
  mounted() {
    this.loadRecommendations()
  },
  methods: {
    onClick(e) {
      if (mz && mz.track) {
        mz.track('recommendation-click', e)
      }
    },
    getTargetSelector(zoneId) {
      return `[data-mz-zone-id="${zoneId}"]`
    },
    parse(str) {
      try {
        return JSON.parse(str)
      } catch (e) {
        return str
      }
    },
    queryItems() {
      const self = this
      const itemList = document.querySelectorAll('[data-mz-item]')
      let items = []
      Array.from(itemList).forEach(function (item) {
        const value = self.parse(item.dataset.mzItem)
        if (Array.isArray(value)) {
          items = items.concat(value)
        } else {
          items.push(value)
        }
      })
      return items
    },
    queryFilter() {
      const filterList = document.querySelectorAll('[data-mz-filter]')
      const filters = {}
      Array.from(filterList).forEach(function (filter) {
        const key = filter.dataset.mzFilter
        filters[key] = filter.dataset.mzValue
      })
      return filters
    },
    queryAllZones() {
      const zoneList = document.querySelectorAll('[data-mz-zone-id]')
      const zones = []
      Array.from(zoneList).forEach(function (zone) {
        zones.push(zone.dataset.mzZoneId)
      })
      return zones
    },
    loadRecommendations() {
      const self = this
      const zones = this.queryAllZones()
      const items = this.queryItems()
      const filter = this.queryFilter()
      const context = {
        visitId: mz ? mz.getVisitId() : '',
        visitorId: mz ? mz.getVisitorId() : '',
        tags: this.context.tags,
        items,
        filter
      }
      if (zones.length === 0) {
        return
      }
      this.$axios
        .post('/recommendation/query', { zones, context })
        .then((res) => {
          // inject custom css
          Object.keys(res.data.widgets).forEach(function (key) {
            setCustomCSS(key, res.data.widgets[key].layout.customCss)
          })
          self.widgets = res.data.widgets
          self.queryId = res.data.queryId
          self.segments = res.data.segments

          const widgets = []
          Object.keys(res.data.widgets).forEach(function (key) {
            widgets.push({
              zoneId: key,
              total: res.data.widgets[key].slides.length,
              uids: res.data.widgets[key].slides.map((x) => x.item.id),
              primary: res.data.widgets[key].slides.filter(
                (x) => x.source === 'primary'
              ).length
            })
          })

          if (mz && mz.track && Object.keys(self.widgets).length > 0) {
            mz.track('recommendation', {
              queryId: self.queryId,
              zones,
              segments: self.segments,
              context,
              widgets
            })
          }
        })
    }
  }
}
</script>

<style></style>
